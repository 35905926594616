<template>
  <div class="main">
    <heads></heads>
    <banners></banners>

    <div class="cons">
      <div class="tijiao"><el-button type="primary" @click="comeback">重新提交</el-button></div>

      <div class="ztflhtitle">
        <div class="ztflhtitle-list"><b>参考文献</b></div>
        <div class="ztflhtitle-list" v-for="(item,key) in ckwxlist" :key="key">[{{key+1}}]{{item.writerwenxian}}.{{item.arttitle}}[J].{{item.qkname}},{{item.year}}({{item.qs}}):{{item.wzpagefw}}</div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script setup>
import { reactive, ref, getCurrentInstance } from "vue";
import { ElMessage } from "element-plus";
import { get } from "@/utils/index";
import { useRouter } from "vue-router";
import { constant } from "lodash";
const router = useRouter();
const { proxy } = getCurrentInstance();

// 参考文献
let ckwxlist=reactive([])

testLog();
// 获取数据
function testLog() {
  get("/journalRecommend/GetBynidTgfx", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    // console.log(res)
    ckwxlist.push(...res.data)
    ckwxlist.forEach((e) => {
      e.writerwenxian = "";
      e.writer.split(";").forEach((ele, key) => {
        if(e.writer.split(";").length<=3){
          if(key < e.writer.split(";").length-1){
             e.writerwenxian += ele + ",";
          }else{
            e.writerwenxian += ele;
          }
        }else{
          if(key < 2){
             e.writerwenxian += ele + ",";
          }else{
            e.writerwenxian += ele + "等";
          }
        }
      });
    });
  });
}
// 返回
const comeback=()=>{
  window.history.go(-1)
}
</script>

<style scoped>
.main {
  background: #fff;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 5px 0 50px 0;
  font-size: 14px;
}
.ztflhtitle-list{
  background: #f2f2f2;
  padding: 10px 20px;
  margin: 10px 0;
}
.tijiao{
  padding: 10px 0 0 0;
}
</style>
